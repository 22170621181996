import React from 'react';
import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './FormAchievement.module.scss';
import Image from '@app/components/server/Image/Image';
import {
    ACHIEVEMENTS_MAP,
    AchievementType,
} from './FormAchievement.helpers';

interface IFormAchievementProps {
    achievement: AchievementType;
    concise?: boolean;
    className?: string;
    wide?: boolean;
    wideIcon?: boolean;
}

const FormAchievement: React.FC<IFormAchievementProps> = props => {
    const {
        achievement,
        concise,
        wide,
        wideIcon,
        className,
    } = props;

    const {
        icon,
        text,
        url,
    } = ACHIEVEMENTS_MAP[achievement];

    const classNames = mergeClassNames([
        buildClassNames(styles, ['form__achievement', concise && 'form__achievement--concise', wide && 'form__achievement--wide']),
        className,
    ]);

    return (
        <a href={url} className={classNames} target="_blank" rel="nofollow noreferrer noopener">
            <span className={buildClassNames(styles, ['form__achievement-icon-wrapper', wideIcon && 'form__achievement-icon-wrapper--wide'])}>
                <Image src={icon} alt="" fill />
            </span>
            <p>{text}</p>
        </a>
    );
};

export default FormAchievement;
